import { Injectable, ViewEncapsulation } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "environments/environment";
import { User, Role } from "app/auth/models";
import { ToastrService } from "ngx-toastr";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  encapsulation: ViewEncapsulation.None;
  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Admin
    );
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Admin
    );
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */

  login(email: string, password: string) {
    return (
      this._http
        //.post<any>(`${environment.apiUrl}/login`, params)
        .post<any>(`${environment.apiUrl}/Login`, { email, password })
        .pipe(
          map((user) => {
            // login successful if there's a jwt token in the response
            debugger;
            if (user.message == "User is valid!") {
              return user.message;
            }
          })
        )
    );
  }

  loginvalidate(email: string, otp: number) {
    const helper = new JwtHelperService();
    //debugger;
    return (
      this._http
        //.post<any>(`${environment.apiUrl}/login`, params)
        .post<any>(`${environment.apiUrl}/ValidateOTP`, { email, otp })
        .pipe(
          map((user) => {
            // login successful if there's a jwt token in the response
            debugger;
            if (user && user.token) {
              var usuario = helper.decodeToken(user.token);

              switch (usuario.name_role) {
                case "ROLE_MANAGER":
                  usuario.role = "ROLE_MANAGER";
                  break;
                case "CaseManager":
                  usuario.role = "CaseManager";
                  break;
                case  "Enfermera":
                  usuario.role = "Enfermera";
                  break;
                default:
                  usuario.role = "User";
                  break;
              }

              localStorage.setItem("currentUser", JSON.stringify(usuario));

              this.currentUserSubject.next(usuario);
            }
            return usuario;
          })
        )
    );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    // notify
    this.currentUserSubject.next(null);
  }
}
